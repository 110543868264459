import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoleGuardService } from '../../auth/role-guard.service';
import { DialogCargadorService } from '../../equipos-dinamicos/services/dialog-cargador-service.service';
import { GlobalFunctionsService } from '../../sharedServices/global-functions.service';

@Component({
  selector: 'selector-empresa',
  templateUrl: './selector-empresa.component.html',
  styleUrls: ['./selector-empresa.component.scss']
})
export class SelectorEmpresaComponent implements OnInit {
  @Input() filtros = [];//EMPRESA A MOSTRAR
  @Output() selectorChanged = new EventEmitter<string>();
  empresas = [];
  subempresas = [];
  empresa;
  constructor(
    private rguard: RoleGuardService,
    private dService: DialogCargadorService,
    private gf: GlobalFunctionsService,
  ) { }

  ngOnInit() {
    this.gf.GetEmpresa().then(x => {
      this.empresa = x;

      this.getEmpresas();

    })
  }
  isDhemax() {
    return this.rguard.isDhemax();
  }

  getEmpresas() {
    this.empresas = [];
    this.dService.getEmpresas().subscribe(empresas => {

      if (this.isDhemax()) {
        if (this.filtros.length == 0) {
          this.empresas = empresas;
        } else {

          empresas.forEach(x => {
            let temp = this.filtros.find(y => { return y == x.id });
            if (temp) {
              this.empresas.push(x);
            }
          })
        }

      }
      this.getSubEmpresas();
    })

  }
  getSubEmpresas() {
    this.dService.getSubEmpresasByEmpresaID({ empresas_id: this.empresa.id }).subscribe(res => {
      if (this.empresa.id != 1) {
        this.empresas.push(this.empresa);
      }
      if (res.length > 0) {
        res.forEach(x => {
            this.empresas.push(x);
        })
      }
      this.subempresas = res;
      this.subempresas = this.subempresas.filter(Boolean);
      this.empresas = this.empresas.filter(Boolean); 
    })

    this.empresas.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
   
  }
  seleccionEmpresa(event) {
    this.selectorChanged.emit(event.value);
  }

}
